import './App.css';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { left } from '@popperjs/core';

function App() {
  let itemData = [
    // column 1
    {img:'pics/1001.jpeg',title:'1001.jpeg'},
    {img:'pics/1026.jpeg',title:'1026.jpeg'},
    {img:'pics/1025.jpeg',title:'1025.jpeg'},
    {img:'pics/1037.jpeg',title:'1037.jpeg'},
    {img:'pics/1136.jpeg',title:'1136.jpeg'},
    {img:'pics/1012.jpeg',title:'1012.jpeg'},
    {img:'pics/1016.jpeg',title:'1016.jpeg'},
    {img:'pics/1041.jpeg',title:'1041.jpeg'},
    {img:'pics/1033.jpeg',title:'1033.jpeg'},
    {img:'pics/1021.jpeg',title:'1021.jpeg'},
    {img:'pics/1045.jpeg',title:'1045.jpeg'},
    {img:'pics/1048.jpeg',title:'1048.jpeg'},
    {img:'pics/1052.jpeg',title:'1052.jpeg'},
    {img:'pics/1101.jpeg',title:'1101.jpeg'},
    {img:'pics/1105.jpeg',title:'1105.jpeg'},
    {img:'pics/1109.jpeg',title:'1109.jpeg'},
    {img:'pics/1113.jpeg',title:'1113.jpeg'},
    {img:'pics/1133.jpeg',title:'1133.jpeg'},
    {img:'pics/1121.jpeg',title:'1121.jpeg'},
    {img:'pics/1125.jpeg',title:'1125.jpeg'},
    {img:'pics/1129.jpeg',title:'1129.jpeg'},
    {img:'pics/1117.jpeg',title:'1117.jpeg'},
    {img:'pics/1137.jpeg',title:'1137.jpeg'},

    // column 2
    {img:'pics/1002.jpeg',title:'1002.jpeg'},
    {img:'pics/1008_b.jpeg',title:'1008_b.jpeg'},
    {img:'pics/1042.jpeg',title:'1042.jpeg'},
    {img:'pics/1034.jpeg',title:'1034.jpeg'},
    {img:'pics/1143.jpeg',title:'1143.jpeg'},
    {img:'pics/1009.jpeg',title:'1009.jpeg'},
    {img:'pics/1013.jpeg',title:'1013.jpeg'},
    {img:'pics/1020.jpeg',title:'1020.jpeg'},
    {img:'pics/1030.jpeg',title:'1030.jpeg'},
    {img:'pics/1046.jpeg',title:'1046.jpeg'},
    {img:'pics/1038.jpeg',title:'1038.jpeg'},
    {img:'pics/1049.jpeg',title:'1049.jpeg'},
    {img:'pics/1053.jpeg',title:'1053.jpeg'},
    {img:'pics/1102.jpeg',title:'1102.jpeg'},
    {img:'pics/1106.jpeg',title:'1106.jpeg'},
    {img:'pics/1110.jpeg',title:'1110.jpeg'},
    {img:'pics/1135.jpeg',title:'1135.jpeg'},
    {img:'pics/1134.jpeg',title:'1134.jpeg'},
    {img:'pics/1118.jpeg',title:'1118.jpeg'},
    {img:'pics/1122.jpeg',title:'1122.jpeg'},
    {img:'pics/1138.jpeg',title:'1138.jpeg'},
    {img:'pics/1139.jpeg',title:'1139.jpeg'},
    {img:'pics/1124.jpeg',title:'1124.jpeg'},
    {img:'pics/1114.jpeg',title:'1114.jpeg'},

    // column 3
    {img:'pics/1003.jpeg',title:'1003.jpeg'},
    {img:'pics/1007.jpeg',title:'1007.jpeg'},
    {img:'pics/1039.jpeg',title:'1039.jpeg'},
    {img:'pics/1043.jpeg',title:'1043.jpeg'},
    {img:'pics/1029.jpeg',title:'1029.jpeg'},
    {img:'pics/1035.jpeg',title:'1035.jpeg'},
    {img:'pics/1014.jpeg',title:'1014.jpeg'},
    {img:'pics/1019.jpeg',title:'1019.jpeg'},
    {img:'pics/1023.jpeg',title:'1023.jpeg'},
    {img:'pics/1027.jpeg',title:'1027.jpeg'},
    {img:'pics/1031.jpeg',title:'1031.jpeg'},
    {img:'pics/1046.png',title:'1046.png'},
    {img:'pics/1050.jpeg',title:'1050.jpeg'},
    {img:'pics/1054.jpeg',title:'1054.jpeg'},
    {img:'pics/1103.jpeg',title:'1103.jpeg'},
    {img:'pics/1107.jpeg',title:'1107.jpeg'},
    {img:'pics/1140.jpeg',title:'1140.jpeg'},
    {img:'pics/1115.jpeg',title:'1115.jpeg'},
    {img:'pics/1119.jpeg',title:'1119.jpeg'},
    {img:'pics/1123.jpeg',title:'1123.jpeg'},
    {img:'pics/1127.jpeg',title:'1127.jpeg'},
    {img:'pics/1130.jpeg',title:'1130.jpeg'},
    {img:'pics/1144.jpeg',title:'1144.jpeg'},
    {img:'pics/1111.jpeg',title:'1111.jpeg'},

    // column 4
    {img:'pics/1006.jpeg',title:'1006.jpeg'},
    {img:'pics/1008_a.jpeg',title:'1008_a.jpeg'},
    {img:'pics/1024.jpeg',title:'1024.jpeg'},
    {img:'pics/1040.jpeg',title:'1040.jpeg'},
    {img:'pics/1116.jpeg',title:'1116.jpeg'},
    {img:'pics/1036.jpeg',title:'1036.jpeg'},
    {img:'pics/1011.jpeg',title:'1011.jpeg'},
    {img:'pics/1017.jpeg',title:'1017.jpeg'},
    {img:'pics/1028.jpeg',title:'1028.jpeg'},
    {img:'pics/1032.jpeg',title:'1032.jpeg'},
    {img:'pics/1044.jpeg',title:'1044.jpeg'},
    {img:'pics/1047.jpeg',title:'1047.jpeg'},
    {img:'pics/1051.jpeg',title:'1051.jpeg'},
    {img:'pics/1100.jpeg',title:'1100.jpeg'},
    {img:'pics/1104.jpeg',title:'1104.jpeg'},
    {img:'pics/1108.jpeg',title:'1108.jpeg'},
    {img:'pics/1112.jpeg',title:'1112.jpeg'},
    {img:'pics/1141.jpeg',title:'1141.jpeg'},
    {img:'pics/1120.jpeg',title:'1120.jpeg'},
    {img:'pics/1131.jpeg',title:'1131.jpeg'},
    {img:'pics/1132.jpeg',title:'1132.jpeg'},
    {img:'pics/1142.jpeg',title:'1142.jpeg'},
    {img:'pics/1128.jpeg',title:'1128.jpeg'},
    {img:'pics/1126.jpeg',title:'1126.jpeg'},

    //{img:'pics/1005.jpeg',title:'1005.jpeg'},
    //{img:'pics/1022.jpeg',title:'1022.jpeg'},
    //{img:'pics/1010.jpeg',title:'1010.jpeg'},
    //{img:'pics/1004.jpeg',title:'1004.jpeg'},
    //{img:'pics/1015.jpeg',title:'1015.jpeg'},
  ];

  return (
    <div className="App">
      <header className="App-header">
        <div style={{fontSize: "40px", fontFamily: "Libre Baskerville, serif", textAlign: left, width: "100%" }}>
          RIEKO OKUSA | Manicurist<br />
        </div>

        <div>
          <Box sx={{ overflowY: 'scroll', '&::-webkit-scrollbar': {display: 'none'} }}>
            <ImageList variant="masonry" cols={4} gap={16}>
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    srcSet={`${item.img}?w=256&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=256&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </div>
      </header>
    </div>
  );
}

export default App;
